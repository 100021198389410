export default [
  {
    value: "name",
    text: "Name",
    width: 250,
    sortable: true,
    filterable: true,
  },
  {
    value: "serial",
    text: "Kit Serial #",
    width: 110,
    sortable: true,
    filterable: true,
  },
  {
    value: "status",
    text: "Status",
    width: 110,
    sortable: true,
  },
  {
    value: "alarms",
    text: "Alarms",
    width: 110,
    sortable: false,
  },
  {
    value: "nvrVersion",
    text: "NVR Version",
    width: 40,
    align: "center",
    sortable: false,
    filterable: true,
  },
  {
    value: "nvrSerial",
    text: "NVR Serial #",
    width: 110,
    sortable: false,
    filterable: true,
  },
  {
    value: "nvrModel",
    text: "NVR Model",
    width: 40,
    align: "center",
    sortable: true,
    filterable: true,
  },
  {
    value: "nvrDeviceType",
    text: "SBC Type",
    width: 40,
    align: "center",
    sortable: false,
  },
  {
    value: "projectName",
    text: "Project",
    width: 160,
    sortable: true,
    filterable: true,
  },
  {
    value: "countryId",
    text: "Country",
    width: 110,
    sortable: true,
  },
  {
    value: "powerType",
    text: "Power Type",
    width: 110,
    sortable: true,
  },
  {
    value: "createdAt",
    text: "Created At",
    width: 150,
    sortable: true,
  },
]
