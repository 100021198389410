<template>
  <v-container fluid class="pa-0">
    <ERow>
      <ECol cols="12" class="py-0">
        <ReportTable
          name="kits"
          :provider="provider"
          :headers="filteredHeaders"
          :filter-fields="filterFields"
          sort-by="created_at"
          actions-portal-target="breadcrumbs-right"
        >
          <!-- Kit Name -->
          <template #item.name="{ item, header }">
            <NuxtLink :to="`/kits/${item.serial}`">
              <ETruncatedDiv :width="header.width">{{
                item.name
              }}</ETruncatedDiv>
            </NuxtLink>
          </template>

          <template #item.alarms="{ item }">
            <EReadMore
              v-if="getAlarms(item)"
              class="red--text text--darken-2"
              :content="getAlarms(item)"
              :max-length="2"
            />
            <div v-else class="green--text text--darken-2">Stable</div>
          </template>

          <template #item.nvrDeviceType="{ item }">
            {{ getSBCType(item) }}
          </template>

          <!-- Country Name -->
          <template #item.countryId="{ item }">
            <FlagIcon :code="getCountryCode(item)" />
            {{ item.countryName }}
          </template>

          <!-- Project name -->
          <template #item.projectName="{ item, header }">
            <nuxt-link
              :to="`/projects#project_dialog=true&project_exid=${item.project.exid}`"
            >
              <ETruncatedDiv :width="header.width">
                {{ item.project.name }}
              </ETruncatedDiv>
            </nuxt-link>
          </template>

          <!-- Created At -->
          <template #item.createdAt="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>

          <!-- Kit Status -->
          <template #item.status="{ item }">
            <span :class="getKitStatusColor(item.status)">
              {{ getKitStatusText(item.status) }}
            </span>
          </template>

          <!-- NVR Version -->
          <template #item.nvrVersion="{ item }">
            <span>
              {{ item.nvr?.version || "-" }}
            </span>
          </template>

          <!-- NVR Serial Number -->
          <template #item.nvrSerial="{ item }">
            <span v-if="item.nvr?.serial">
              {{ item.nvr?.serial || "-" }}
            </span>
          </template>

          <!-- Nerves URL -->
          <template #item.nvrModel="{ item }">
            <span> {{ toTitleCase(item.nvr?.model) || "-" }} </span>
            <OpenInNewWindowButton
              v-if="item.nvr?.nerves"
              hint="Open in Nerves"
              svg-icon="nerves"
              :target-link="getNervesDeviceUrl(item)"
              :icon-props="{
                width: '19',
                height: '11',
              }"
            />
          </template>
        </ReportTable>
      </ECol>
    </ERow>
  </v-container>
</template>

<script lang="ts">
import KitsHeader from "@/components/kits/kitsHeaders"
import ReportTable from "@/components/ReportTable"
import filterFields from "@/components/kits/kitsSearchFilters"
import OpenInNewWindowButton from "@/components/OpenInNewWindowButton.vue"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"
import { useBreadcrumbStore } from "@/stores/breadcrumb"
import countries from "@evercam/shared/constants/countries"
import {
  KitAlarms,
  KitStatusesIdsClasses,
  NvrDeviceTypes,
} from "@/components/constants.js"
import { PERMISSIONS } from "@/constants/permissions"
import FlagIcon from "@evercam/shared/components/FlagIcon.vue"
import { useKitSummaryStore } from "@/stores/kitSummary"

export default {
  meta: {
    requiredPermissions: [PERMISSIONS.KITS.VIEW],
  },
  name: "Kits",
  components: {
    FlagIcon,
    OpenInNewWindowButton,
    ReportTable,
  },
  data: () => {
    return {
      headers: KitsHeader,
      countries: countries,
      loading: false,
      editedItem: {},
      filterFields,
    }
  },
  head() {
    return {
      title: "Admin - Kits",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content: "",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useReportStore, useBreadcrumbStore),
    provider() {
      return AdminApi.kits.getAllKits
    },
    canEditKits() {
      return this.$permissions.user.can(PERMISSIONS.KITS.UPDATE)
    },
    canDeleteKits() {
      return this.$permissions.user.can(PERMISSIONS.KITS.DELETE)
    },
    filteredHeaders() {
      return this.headers.filter(
        (header) =>
          this.canEditKits || this.canDeleteKits || header.value !== "actions"
      )
    },
  },
  watch: {
    "reportStore.searchFilters": {
      async handler(params) {
        const queryParams = this.toQueryString(params)
        useKitSummaryStore().previousKitsPageFullPath = queryParams
          ? `/kits?${queryParams}`
          : "/kits"
        this.initBreadcrumbs()
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    initBreadcrumbs() {
      this.breadcrumbStore.breadcrumbs = [
        {
          name: "Home",
          href: "/",
          icon: "fa-house",
        },
        {
          name: "Kits",
          icon: "fa-box",
        },
      ]
    },
    getAlarms(item) {
      if (!item?.alarms?.length) {
        return ""
      }

      return item?.alarms?.map(
        (alarm) => KitAlarms.find(({ value }) => value === alarm)?.name
      )
    },
    getKitStatusColor(status) {
      return KitStatusesIdsClasses[status]?.class || ""
    },
    getKitStatusText(status) {
      return KitStatusesIdsClasses[status]?.value || status
    },
    getNervesDeviceUrl(item) {
      return `${this.$config.public.nervesDashboardUrl}/devices/${item.nvr?.serial}`
    },
    getCountryCode(item) {
      return countries.find((c) => c.name === item.countryName)?.value
    },
    getSBCType(item) {
      return NvrDeviceTypes.find((t) => t.value === item?.nvr?.deviceType)?.name
    },
  },
}
</script>
