import {
  BooleanOptions,
  KitAlarms,
  KitStatuses,
  NvrDeviceTypes,
} from "@/components/constants.js"
import { CountryId, PowerType } from "@evercam/shared/types"
import { toTitleCase } from "@evercam/shared/utils"
import vendorModels from "@evercam/shared/constants/vendorModels"

function getCountryNames() {
  return Object.keys(CountryId)
    .filter(isNaN)
    .map((key) => ({ name: toTitleCase(key), value: CountryId[key] }))
    .sort((a, b) => a.name.localeCompare(b.name))
}

export default {
  name: {
    component: "TextFieldSearchFilter",
  },
  serial: {
    component: "TextFieldSearchFilter",
  },
  nvrVersion: {
    component: "TextFieldSearchFilter",
  },
  nvrSerial: {
    component: "TextFieldSearchFilter",
  },
  nvrNerves: {
    component: "SelectSearchFilter",
    isGlobalFilter: true,
    width: 80,
    attributes: {
      items: [{ value: "", name: "All" }, ...BooleanOptions],
      label: "Nerves",
      value: "",
    },
  },
  nvrModel: {
    component: "SelectSearchFilter",
    attributes: {
      items: [{ value: "", name: "All" }, ...vendorModels.NVRMODELS],
      value: "",
    },
  },
  nvrDeviceType: {
    component: "SelectSearchFilter",
    attributes: {
      items: [{ value: "", name: "All" }, ...NvrDeviceTypes],
      value: "",
    },
  },
  projectName: {
    component: "TextFieldSearchFilter",
  },
  status: {
    component: "SelectSearchFilter",
    attributes: {
      items: [{ value: "", name: "All" }, ...KitStatuses],
      value: "",
    },
  },
  alarms: {
    component: "SelectSearchFilter",
    attributes: {
      items: KitAlarms,
      multiple: true,
    },
  },
  countryId: {
    component: "SelectSearchFilter",
    attributes: {
      items: [{ value: "", name: "All" }, ...getCountryNames()],
      "menu-props": { offsetY: true },
      isAutocomplete: true,
      value: "",
    },
  },
  powerType: {
    component: "SelectSearchFilter",
    attributes: {
      items: Object.entries(PowerType).map(([name, value]) => ({
        name,
        value,
      })),
      multiple: true,
      "menu-props": { offsetY: true },
      isAutocomplete: true,
    },
  },
}
